<template>
  <b-modal
    v-model="modalOpen"
    no-fade
    ref="edit-record"
    :no-close-on-backdrop="true"
    hide-footer
    hide-header
    hide-backdrop
    size="xl"
  >
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Editar Expediente</h4>
              </div>
              <div class="col-2">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="reset"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="name">Nombre</label>
                  <input v-model="payload.name" type="text" class="form-control" id="name" placeholder="Nombre" readonly disabled>
                  <div v-if="errors.hasOwnProperty('name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.name[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="instance_id">Instancia</label>
                  <v-select
                    v-model="payload.instance_id"
                    :options="instancesData"
                    id="instance_id"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('instance_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.instance_id[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="court_id">Tribunal</label>
                  <v-select
                    v-model="payload.court_id"
                    :options="courtsData"
                    id="court_id"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('court_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.court_id[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="client_id">Cliente</label>
                  <v-select
                    v-model="payload.client_id"
                    :options="clientsData"
                    id="client_id"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('client_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.client_id[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-auto"><label for="number">Número APT</label></div>
                    <div class="col-auto">
                      <button type="button" class="btn btn-sm btn-link p-0 mb-2" :class="payload.file_row ? 'text-info' : 'text-dark'" @click.prevent="toggleFilemanager">
                        <i class="mdi mdi-folder-plus"></i>
                      </button>
                    </div>
                  </div>
                  <input v-model="payload.number" type="text" class="form-control" id="number" placeholder="Número APT">
                  <div v-if="errors.hasOwnProperty('number')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.number[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6" v-show="filemanager_update">
                  <label for="file_row">Fila</label>
                  <input v-model="payload.file_row" type="text" class="form-control" id="file_row" placeholder="Fila">
                  <div v-if="errors.hasOwnProperty('file_row')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.file_row[0]}}</small>
                  </div>
                  <label for="file_column" class="mt-4">Columna</label>
                  <input v-model="payload.file_column" type="text" class="form-control" id="file_column" placeholder="Columna">
                  <div v-if="errors.hasOwnProperty('file_column')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.file_column[0]}}</small>
                  </div>
                  <label for="file_container" class="mt-4">Gaveta</label>
                  <input v-model="payload.file_container" type="text" class="form-control" id="file_container" placeholder="Gaveta">
                  <div v-if="errors.hasOwnProperty('file_container')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.file_container[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="notes">Notas</label>
                <textarea v-model="payload.notes" name="notes" id="notes" cols="30" rows="5" class="form-control"></textarea>
                <div v-if="errors.hasOwnProperty('notes')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.notes[0]}}</small>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="lawyer_id">Abogado</label>
                  <v-select
                    v-model="payload.lawyer_id"
                    :options="lawyersData"
                    id="lawyer_id"
                    :reduce="r => r.code"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('lawyer_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.lawyer_id[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="against_to">Parte Contraria</label>
                  <input v-model="payload.against_to" type="text" class="form-control" id="against_to" placeholder="Parte Contraria">
                  <div v-if="errors.hasOwnProperty('against_to')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.against_to[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="defense_lawyer">Abogado Contrario</label>
                  <input v-model="payload.defense_lawyer" type="text" class="form-control" id="defense_lawyer" placeholder="Abogado Contrario">
                  <div v-if="errors.hasOwnProperty('defense_lawyer')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.defense_lawyer[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button @click.prevent="update" class="btn btn-gradient-info btn-md">Actualizar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  name: 'UpdateRecord',
  data () {
    return {
      errors: {}
    }
  },
  mounted () {
    this.getInstancesDropdown()
    this.getCourtsDropdown()
    this.getClientsDropdown()
    this.getLawyersDropdown()
  },
  methods: {
    ...mapMutations({
      TOGGLE_MODAL_UPDATE: 'Record/TOGGLE_MODAL_UPDATE',
      RECORD_TOGGLE_MODAL_FILEMANAGER: 'Record/RECORD_TOGGLE_MODAL_FILEMANAGER'
    }),
    ...mapActions({
      updateRecord: 'Record/update',
      getInstancesDropdown: 'Instance/getInstancesDropdown',
      getCourtsDropdown: 'Court/getCourts',
      getClientsDropdown: 'Client/getClients',
      getLawyersDropdown: 'User/getAllLawyers'
    }),
    update () {
      this.updateRecord(this.payload)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.name = null
      this.errors = {}
      this.TOGGLE_MODAL_UPDATE(false)
      this.$events.fire('refresh-table')
    },
    toggleFilemanager () {
      this.RECORD_TOGGLE_MODAL_FILEMANAGER()
    }
  },
  computed: {
    ...mapGetters({
      modalUpdate: 'Record/modalUpdate',
      instancesDropdown: 'Instance/instances_dropdown',
      courtsDropdown: 'Court/courts_dropdown',
      clientsDropdown: 'Client/clients_dropdown',
      layersDropdown: 'User/lawyers_dropdown',
      filemanager_update: 'Record/modal_file_update'
    }),
    modalOpen () {
      return this.modalUpdate
    },
    instancesData () {
      return this.instancesDropdown
    },
    courtsData () {
      return this.courtsDropdown
    },
    clientsData () {
      return this.clientsDropdown
    },
    lawyersData () {
      return this.layersDropdown
    }
  }
}
</script>
