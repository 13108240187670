<template>
  <b-modal
    v-model="modalOpen"
    no-fade ref="create-instance"
    :no-close-on-backdrop="true"
    hide-footer hide-header hide-backdrop
    size="xl"
  >
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Agregar Expendiente</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="name">Nombre</label>
                  <input v-model="form.name" type="text" class="form-control" id="name" placeholder="Nombre">
                  <div v-if="errors.hasOwnProperty('name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.name[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-auto"><label for="client_id">Cliente</label></div>
                    <div class="col-auto">
                      <button type="button" class="btn btn-sm btn-link p-0 mb-2" @click.prevent="CLIENT_TOGGLE_MODAL_CREATE">
                        <i class="mdi mdi-plus"></i>
                      </button>
                    </div>
                  </div>
                  <v-select
                    v-model="form.client_id"
                    :options="clientsData"
                    id="client_id"
                    :reduce="r => r.code"
                    :placeholder="'Selecciona un cliente'"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('client_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.client_id[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="court_id">Tribunal</label>
                  <v-select
                    v-model="form.court_id"
                    :options="courtsData"
                    id="court_id"
                    :reduce="r => r.code"
                    :placeholder="'Selecciona un tribunal'"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('court_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.court_id[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="instance_id">Instancia</label>
                  <v-select
                    v-model="form.instance_id"
                    :options="instancesData"
                    id="instance_id"
                    :reduce="r => r.code"
                    :placeholder="'Selecciona una instancia'"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('instance_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.instance_id[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-auto"><label for="number">Número APT</label></div>
                    <div class="col-auto">
                      <button type="button" class="btn btn-sm btn-link p-0 mb-2" :class="form.file_row && form.file_column ? 'text-info' : 'text-dark'" @click.prevent="toggleFilemanager">
                        <i class="mdi mdi-folder-plus"></i>
                      </button>
                    </div>
                  </div>
                  <input v-model="form.number" type="text" class="form-control" id="number" placeholder="Número APT">
                  <div v-if="errors.hasOwnProperty('number')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.number[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6" v-show="filemanager_update">
                  <label for="file_row">Fila</label>
                  <input v-model="form.file_row" type="text" class="form-control" id="file_row" placeholder="Fila">
                  <div v-if="errors.hasOwnProperty('file_row')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.file_row[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row" v-show="filemanager_update">
                <div class="form-group col-lg-6">
                  <label for="file_column">Columna</label>
                  <input v-model="form.file_column" type="text" class="form-control" id="file_column" placeholder="Columna">
                  <div v-if="errors.hasOwnProperty('file_column')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.file_column[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="file_container">Gaveta</label>
                  <input v-model="form.file_container" type="text" class="form-control" id="file_container" placeholder="Gaveta">
                  <div v-if="errors.hasOwnProperty('file_container')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.file_container[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="lawyer_id">Abogado</label>
                  <v-select
                    v-model="form.lawyer_id"
                    :options="lawyersData"
                    id="lawyer_id"
                    :reduce="r => r.code"
                    :placeholder="'Selecciona un abogado'"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('lawyer_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.lawyer_id[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="next_audience">Próxima Audiencia</label>
                  <datetime v-model="form.next_audience" :config="dateConfig" id="next_audience" class="form-control"></datetime>
                  <div v-if="errors.hasOwnProperty('next_audience')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.next_audience[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="against_to">Parte Contraria</label>
                  <input v-model="form.against_to" type="text" class="form-control" id="against_to" placeholder="Parte Contraria">
                  <div v-if="errors.hasOwnProperty('against_to')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.against_to[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="defense_lawyer">Abogado Contrario</label>
                  <input v-model="form.defense_lawyer" type="text" class="form-control" id="defense_lawyer" placeholder="Abogado Contrario">
                  <div v-if="errors.hasOwnProperty('defense_lawyer')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.defense_lawyer[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="notes">Detalles Del Caso</label>
                <textarea v-model="form.notes" name="notes" id="notes" cols="30" rows="5" class="form-control"></textarea>
                <div v-if="errors.hasOwnProperty('notes')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.notes[0]}}</small>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button @click.prevent="create" class="btn btn-outline-info btn-md">Guardar</button>
                </div>
                <div class="col-auto">
                  <button @click.prevent="create(true)" class="btn btn-gradient-info btn-md">Continuar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <client-create></client-create>
  </b-modal>
</template>

<script>
import ClientCreate from '@/components/CreateClient'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'

export default {
  name: 'CreateRecord',
  data () {
    return {
      form: {
        name: null,
        instance_id: null,
        record_type_id: 1,
        payment_type_id: 1,
        court_id: null,
        client_id: null,
        number: null,
        notes: null,
        lawyer_id: null,
        against_to: null,
        defense_lawyer: null,
        file_row: null,
        file_column: null,
        file_container: null,
        next_audience: null
      },
      errors: {},
      dateConfig: {
        altInput: true,
        enableTime: true,
        plugins: [new ConfirmDatePlugin({
          confirmIcon: '<i class="fa fa-check"></i>',
          confirmText: 'OK ',
          showAlways: false
        })],
        altFormat: 'F j, Y G:i K',
        dateFormat: 'Y-m-d H:i',
        defaultHour: 9,
        defaultMinute: 0,
        time_24hr: false,
        locale: Spanish
      }
    }
  },
  mounted () {
    this.getInstancesDropdown()
    this.getCourtsDropdown()
    this.getClientsDropdown()
    this.getLawyersDropdown()
  },
  methods: {
    ...mapMutations({
      TOGGLE_MODAL_CREATE: 'Record/TOGGLE_MODAL_CREATE',
      CLIENT_TOGGLE_MODAL_CREATE: 'Client/TOGGLE_MODAL_CREATE',
      RECORD_TOGGLE_MODAL_FILEMANAGER: 'Record/RECORD_TOGGLE_MODAL_FILEMANAGER'
    }),
    ...mapActions({
      createRecord: 'Record/create',
      getInstancesDropdown: 'Instance/getInstancesDropdown',
      getCourtsDropdown: 'Court/getCourts',
      getClientsDropdown: 'Client/getClients',
      getLawyersDropdown: 'User/getAllLawyers'
    }),
    create (review = false) {
      this.createRecord(this.form)
        .then((response) => {
          this.reset()
          if (review) {
            this.$router.push({ name: 'record_details', params: { id: response.data.data.id } })
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.form.name = null
      this.form.instance_id = null
      this.form.court_id = null
      this.form.client_id = null
      this.form.number = null
      this.form.notes = null
      this.form.lawyer_id = null
      this.form.against_to = null
      this.form.defense_lawyer = null
      this.errors = {}
      this.TOGGLE_MODAL_CREATE(false)
      this.$events.fire('refresh-table')
    },
    resetFileManagerModal () {
      this.RECORD_TOGGLE_MODAL_FILEMANAGER(false)
    },
    toggleFilemanager () {
      this.RECORD_TOGGLE_MODAL_FILEMANAGER()
    }
  },
  computed: {
    ...mapGetters({
      modalCreate: 'Record/modalCreate',
      instancesDropdown: 'Instance/instances_dropdown',
      courtsDropdown: 'Court/courts_dropdown',
      clientsDropdown: 'Client/clients_dropdown',
      layersDropdown: 'User/lawyers_dropdown',
      filemanager_update: 'Record/modal_file_update'
    }),
    modalOpen () {
      return this.modalCreate
    },
    instancesData () {
      return this.instancesDropdown
    },
    courtsData () {
      return this.courtsDropdown
    },
    clientsData () {
      return this.clientsDropdown
    },
    lawyersData () {
      return this.layersDropdown
    }
  },
  components: {
    ClientCreate
  }
}
</script>
