<template>
  <layout>
    <template slot="content">
      <div>
        <div class="page-header mb-3 mb-md-2">
          <h3 class="page-title"> Expedientes </h3>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Expedientes</a></li>
              <li class="breadcrumb-item active" aria-current="page">Todos los expedientes</li>
            </ol>
          </nav>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-between align-items-center mb-4">
                  <div v-if="displaySearch" class="col-12 col-md-10 mb-4 md:mb-0">
                    <div class="row items-center pt-3 rounded">
                      <div class="col-12 col-md-4 mb-3 ">
                        <v-select
                            v-model="recordFilters.client"
                            :options="clients_dropdown"
                            id="client_id"
                            :reduce="r => r.code"
                            :placeholder="'Selecciona cliente'"
                            @input="doFilter"
                          >
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              No se encontraron resultados para <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>
                              Empiece a escribir para buscar.
                            </em>
                          </template>
                        </v-select>
                      </div>
                      <div class="col-12 col-md-4 mb-3 ">
                        <input
                          v-model="recordFilters.name"
                          @keyup="doFilter"
                          type="text"
                          class="form-control form-control-sm"
                          name="name"
                          placeholder="Buscar Nombre"
                        >
                      </div>
                      <div class="col-12 col-md-4 mb-3 ">
                        <input
                          v-model="recordFilters.against_to"
                          @keyup="doFilter"
                          type="text"
                          class="form-control form-control-sm"
                          name="against_to"
                          placeholder="Buscar Contraparte"
                        >
                      </div>
                      <div class="col-12 col-md-4 mb-3 ">
                        <input
                          v-model="recordFilters.file_row"
                          @keyup="doFilter"
                          type="text"
                          class="form-control form-control-sm"
                          name="file_row"
                          placeholder="Buscar Fila"
                        >
                      </div>
                      <div class="col-12 col-md-4 mb-3 ">
                        <input
                          v-model="recordFilters.file_column"
                          @keyup="doFilter"
                          type="text"
                          class="form-control form-control-sm"
                          name="file_column"
                          placeholder="Buscar Columna"
                        >
                      </div>
                      <div class="col-auto mb-3">
                        <button
                          class="btn btn-sm btn-outline-secondary"
                          type="button"
                          @click.prevent="displaySearch = false"
                        >
                          <i class="mdi mdi-close"></i> Cerrar
                        </button>
                      </div>
                      <div class="col-auto mb-3">
                        <button
                          class="btn btn-sm btn-outline-info"
                          type="button"
                          @click.prevent="resetFilter"
                        >
                          Limpiar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else class="col-auto">
                    <button class="btn btn-sm btn-transparent" @click.prevent="displaySearch = true">
                      <i class="mdi mdi-filter-variant"></i> Filtros
                    </button>
                  </div>
                  <div class="col-auto ml-auto">
                    <button class="btn btn-sm btn-gradient-info" @click.prevent="openModelCreate">
                      <i class="mdi mdi-plus"></i> Nuevo
                    </button>
                  </div>
                </div>
                <datatable
                  :api="apiEndPoint"
                  :fields="fields"
                  :otherParams="recordFilters"
                  :hide-search="true"
                />
              </div>
            </div>
          </div>
        </div>

        <modal-create></modal-create>
        <modal-update :payload="selected"></modal-update>
        <client-modal-update :payload="clientSelected"></client-modal-update>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/BaseLayout'
import Datatable from '@/components/TableLayout'
import ModalCreate from '@/components/CreateRecord'
import ModalUpdate from '@/components/EditRecord'
import ClientModalUpdate from '@/components/EditClient'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Record',
  props: {
    client_request: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      apiEndPoint: 'api/records',
      fields: [
        {
          name: 'number',
          title: 'No',
          sortField: 'number'
        },
        {
          name: 'record_name',
          title: 'Nombre',
          sortField: 'name'
        },
        {
          name: 'client',
          title: 'Cliente',
          sortField: 'client_id'
        },
        {
          name: 'against_to',
          title: 'Parte Contraria',
          sortField: 'against_to'
        },
        {
          name: 'instance',
          title: 'Instancia',
          formatter: (value) => {
            return value.name
          },
          visible: false,
          sortField: 'instance_id'
        },
        {
          name: 'record_label',
          title: 'Label',
          dataClass: 'text-center',
          formatter: (value) => {
            return value
          },
          visible: false
        }
      ],
      recordFilters: {
        client: '',
        against_to: '',
        name: '',
        file_column: '',
        file_row: ''
      },
      selected: {},
      displaySearch: false,
      clientSelected: {}
    }
  },
  methods: {
    ...mapMutations({
      TOGGLE_MODAL_CREATE: 'Record/TOGGLE_MODAL_CREATE',
      TOGGLE_MODAL_UPDATE: 'Record/TOGGLE_MODAL_UPDATE',
      CLIENT_TOGGLE_MODAL_UPDATE: 'Client/TOGGLE_MODAL_UPDATE'
    }),
    ...mapActions({
      getClients: 'Client/getClients'
    }),
    openModelCreate () {
      this.TOGGLE_MODAL_CREATE()
    },
    openModelUpdate () {
      this.TOGGLE_MODAL_UPDATE()
    },
    openClientModelUpdate () {
      this.CLIENT_TOGGLE_MODAL_UPDATE()
    },
    doFilter () {
      this.$nextTick(() => this.$events.fire('refresh-table'))
    },
    resetFilter () {
      this.recordFilters = {
        client: '',
        against_to: '',
        name: '',
        file_column: '',
        file_row: ''
      }

      this.$nextTick(() => { this.$events.fire('refresh-table') })
    }
  },
  computed: {
    ...mapGetters({
      clients_dropdown: 'Client/clients_dropdown'
    })
  },
  mounted () {
    this.$events.$on('table-item-edit', eventData => {
      this.selected = eventData
      this.openModelUpdate()
    })
    this.$events.$on('table-client-edit', eventData => {
      this.clientSelected = eventData
      this.openClientModelUpdate()
    })
    this.getClients()
  },
  created () {
    if (this.client_request) {
      this.recordFilters.client = this.client_request
    }
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
    this.$events.off('table-client-edit')
  },
  components: {
    Layout,
    Datatable,
    ModalCreate,
    ModalUpdate,
    ClientModalUpdate
  }
}
</script>
